"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HotkeyHandler = void 0;
const util_1 = require("../util");
const keyboard_1 = require("./keyboard");
const touch_1 = require("./touch");
class HotkeyHandler {
    keyboardHotkeys;
    touchHotkeys;
    static instance;
    constructor() {
        this.keyboardHotkeys = keyboard_1.KeyboardHotkeyController.getInstance();
        this.touchHotkeys = touch_1.TouchHotkeyController.getInstance();
    }
    async loadHotkeys() {
        await this.keyboardHotkeys.loadHotkeys();
    }
    registerHotkeys(ref) {
        // keyboard events
        document.addEventListener("keydown", this.keyboardHotkeys.handleKeyDown);
        // touch events
        if (!ref || !ref.current)
            return console.error("Gesture handler could not be initialized. The target element does not exist.");
        ref.current.addEventListener("touchstart", this.touchHotkeys.handleTouchStart, {
            passive: true,
        });
        ref.current.addEventListener("touchmove", this.touchHotkeys.handleTouchMove, {
            passive: true,
        });
        ref.current.addEventListener("touchend", this.touchHotkeys.handleTouchEnd, {
            passive: true,
        });
        // Context-menu is what shows up on mobile on long touch
        ref.current.addEventListener("contextmenu", util_1.preventContextMenu);
    }
    unregisterHotkeys(ref) {
        document.removeEventListener("keydown", this.keyboardHotkeys.handleKeyDown);
        if (!ref || !ref.current)
            return console.error("Gesture handler could not be unregistered. The target element does not exist.");
        ref.current.removeEventListener("touchstart", this.touchHotkeys.handleTouchStart);
        ref.current.removeEventListener("touchmove", this.touchHotkeys.handleTouchMove);
        ref.current.removeEventListener("touchend", this.touchHotkeys.handleTouchEnd);
        ref.current.removeEventListener("contextmenu", util_1.preventContextMenu);
    }
    static getInstance() {
        if (!HotkeyHandler.instance)
            HotkeyHandler.instance = new HotkeyHandler();
        return HotkeyHandler.instance;
    }
}
exports.HotkeyHandler = HotkeyHandler;
