"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDiagram = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const consts_1 = require("@utils/consts");
const diagramStore = (0, toolkit_1.createSlice)({
    name: "diagram",
    initialState: {
        value: localStorage.getItem(consts_1.LocalStorage.DIAGRAM)
            ? JSON.parse(localStorage.getItem(consts_1.LocalStorage.DIAGRAM) || "true")
            : true,
    },
    reducers: {
        setDiagram: (state, action) => {
            state.value = action.payload;
        },
    },
});
exports.setDiagram = diagramStore.actions.setDiagram;
exports.default = diagramStore.reducer;
