"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.preventContextMenu = exports.copyKey = void 0;
const copyKey = (key) => {
    return {
        name: key.name,
        keys: [...key.keys],
        active: key.active,
        isBeingModified: key.isBeingModified,
        isAllowed: key.isAllowed,
        action: key.action,
    };
};
exports.copyKey = copyKey;
const preventContextMenu = (event) => {
    event.preventDefault();
};
exports.preventContextMenu = preventContextMenu;
