"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setModalVisible = exports.removeModal = exports.addModal = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const consts_1 = require("@utils/consts");
const modalStore = (0, toolkit_1.createSlice)({
    name: "modal",
    initialState: {
        value: [],
    },
    reducers: {
        addModal: (state, action) => {
            if (state.value.length >= consts_1.Consts.MAX_MODAL_AMOUNT)
                return;
            const modal = { visible: undefined, content: action.payload };
            state.value.push(modal);
        },
        removeModal: (state, action) => {
            if (state.value.length < 1)
                return;
            state.value.splice(action.payload, 1);
        },
        setModalVisible: (state, action) => {
            if (state.value.length < 1)
                return;
            state.value[state.value.length - 1].visible = action.payload;
        },
    },
});
_a = modalStore.actions, exports.addModal = _a.addModal, exports.removeModal = _a.removeModal, exports.setModalVisible = _a.setModalVisible;
exports.default = modalStore.reducer;
