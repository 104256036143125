"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeInputHandler = void 0;
const timer_1 = require("./timer");
const util_1 = require("../util");
class NativeInputHandler {
    keyboardTimer;
    touchTimer;
    static instance;
    constructor() {
        this.keyboardTimer = timer_1.KeyboardTimerController.getInstance();
        this.touchTimer = timer_1.TouchTimerController.getInstance();
    }
    registerTimer(ref) {
        // keyboard events
        document.addEventListener("keydown", this.keyboardTimer.handleKeyDown);
        document.addEventListener("keyup", this.keyboardTimer.handleKeyUp);
        // touch events
        if (!ref || !ref.current)
            return console.error("Timer touch input handler could not be initialized. The target element does not exist.");
        ref.current.addEventListener("touchstart", this.touchTimer.handleTouchStart, {
            passive: true,
        });
        ref.current.addEventListener("touchend", this.touchTimer.handleTouchEnd, {
            passive: true,
        });
        ref.current.addEventListener("contextmenu", util_1.preventContextMenu);
    }
    unregisterTimer(ref) {
        // keyboard events
        document.removeEventListener("keydown", this.keyboardTimer.handleKeyDown);
        document.removeEventListener("keyup", this.keyboardTimer.handleKeyUp);
        // touch events
        if (!ref || !ref.current)
            return console.error("Timer touch input handler could not be unregistered. The target element does not exist.");
        ref.current.removeEventListener("touchstart", this.touchTimer.handleTouchStart);
        ref.current.removeEventListener("touchend", this.touchTimer.handleTouchEnd);
        ref.current.removeEventListener("contextmenu", util_1.preventContextMenu);
    }
    static getInstance() {
        if (!NativeInputHandler.instance)
            NativeInputHandler.instance = new NativeInputHandler();
        return NativeInputHandler.instance;
    }
}
exports.NativeInputHandler = NativeInputHandler;
