"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimerState = void 0;
const TimerState = {
    IDLE: "IDLE",
    INITIALIZING: "INITIALIZING",
    READY: "READY",
    RUNNING: "RUNNING",
};
exports.TimerState = TimerState;
