"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSchemes = exports.setCurrentScheme = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const colorSchemeStore = (0, toolkit_1.createSlice)({
    name: "colorScheme",
    initialState: {
        value: {
            schemes: {},
            current: undefined,
        },
    },
    reducers: {
        setSchemes(state, action) {
            state.value.schemes = action.payload;
        },
        setCurrentScheme(state, action) {
            state.value.current = action.payload;
        },
    },
});
_a = colorSchemeStore.actions, exports.setCurrentScheme = _a.setCurrentScheme, exports.setSchemes = _a.setSchemes;
exports.default = colorSchemeStore.reducer;
