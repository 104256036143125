"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpringConfig = void 0;
const SpringConfig = {
    HEAVY: {
        mass: 3,
        tension: 256,
        friction: 36,
    },
    LIGHT: {
        mass: 2.5,
        tension: 160,
        friction: 16,
    },
};
exports.SpringConfig = SpringConfig;
