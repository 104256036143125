"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Session {
    name;
    scrambleType;
    method;
    cube;
    index;
    solves;
    constructor(name, scrambleType, index, solves = new Array(), method = "", cube = "") {
        this.name = name;
        this.scrambleType = scrambleType;
        this.index = index;
        this.solves = solves;
        this.method = method;
        this.cube = cube;
    }
    serialize(index) {
        return {
            name: this.name,
            scrambleType: this.scrambleType,
            index: index || this.index,
            solves: this.solves.map(item => item.serialize()),
            method: this.method,
            cube: this.cube,
        };
    }
    addSolve(solve) {
        this.solves.push(solve);
    }
    get getIndex() {
        return this.index;
    }
    set setIndex(index) {
        this.index = index;
    }
    get getScrambleType() {
        return this.scrambleType;
    }
    set setScrambleType(scrambleType) {
        this.scrambleType = scrambleType;
    }
    get getName() {
        return this.name;
    }
    set setName(name) {
        this.name = name;
    }
    get getSolves() {
        return this.solves;
    }
    set setSolves(solves) {
        this.solves = solves;
    }
    get getMethod() {
        return this.method;
    }
    set setMethod(method) {
        this.method = method;
    }
    get getCube() {
        return this.cube;
    }
    set setCube(cube) {
        this.cube = cube;
    }
}
exports.default = Session;
