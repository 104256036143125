"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CubeConfigurations = void 0;
const sr_puzzlegen_1 = require("sr-puzzlegen");
const consts_1 = require("@utils/consts");
const CubeConfigurations = {
    "222": {
        type: sr_puzzlegen_1.Type.CUBE_NET,
        scheme: consts_1.CubeColorScheme,
        size: 2,
    },
    "333": {
        type: sr_puzzlegen_1.Type.CUBE_NET,
        scheme: consts_1.CubeColorScheme,
        size: 3,
    },
    "444": {
        type: sr_puzzlegen_1.Type.CUBE_NET,
        scheme: consts_1.CubeColorScheme,
        size: 4,
    },
    "555": {
        type: sr_puzzlegen_1.Type.CUBE_NET,
        scheme: consts_1.CubeColorScheme,
        size: 5,
    },
    "666": {
        type: sr_puzzlegen_1.Type.CUBE_NET,
        scheme: consts_1.CubeColorScheme,
        size: 6,
    },
    "777": {
        type: sr_puzzlegen_1.Type.CUBE_NET,
        scheme: consts_1.CubeColorScheme,
        size: 7,
    },
    minx: {
        type: sr_puzzlegen_1.Type.MEGAMINX_NET,
        scheme: consts_1.MegaminxColorScheme,
        size: 2,
    },
    pyram: {
        type: sr_puzzlegen_1.Type.PYRAMINX_NET,
        scheme: consts_1.PyraminxColorScheme,
        size: 3,
    },
    skewb: {
        type: sr_puzzlegen_1.Type.SKEWB_NET,
        scheme: consts_1.SkewbColorScheme,
    },
    sq1: {
        type: sr_puzzlegen_1.Type.SQUARE1_NET,
        scheme: consts_1.SkewbColorScheme,
    },
};
exports.CubeConfigurations = CubeConfigurations;
