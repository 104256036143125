"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTimerState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const consts_1 = require("@utils/consts");
const timerStateStore = (0, toolkit_1.createSlice)({
    name: "timer-state",
    initialState: {
        value: consts_1.TimerState.IDLE,
    },
    reducers: {
        setTimerState: (state, action) => {
            state.value = action.payload;
        },
    },
});
exports.setTimerState = timerStateStore.actions.setTimerState;
exports.default = timerStateStore.reducer;
