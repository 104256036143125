"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const consts_1 = require("@utils/consts");
const consts_2 = require("./consts");
class DeltaTimer {
    state;
    startedAt = 0;
    delta = 0;
    updateFrequency;
    callback;
    constructor(updateFrequency) {
        this.state = consts_2.DeltaTimerState.IDLE;
        this.updateFrequency = updateFrequency || consts_1.Consts.TIMER_UPDATE_FREQUENCY;
        // this.callback = callback;
    }
    start() {
        this.startedAt = Date.now();
        this.state = consts_2.DeltaTimerState.RUNNING;
        this.update();
    }
    stop() {
        const stoppedAt = this.delta;
        this.startedAt = 0;
        this.delta = 0;
        this.state = consts_2.DeltaTimerState.IDLE;
        return stoppedAt;
    }
    addCallback(callback) {
        this.callback = callback;
    }
    removeCallback() {
        this.callback = undefined;
    }
    update() {
        setTimeout(() => {
            if (this.state !== consts_2.DeltaTimerState.RUNNING)
                return;
            this.delta = Date.now() - this.startedAt;
            if (this.callback)
                this.callback(this.delta);
            this.update();
        }, this.updateFrequency);
    }
    get getValue() {
        return this.delta;
    }
    get getState() {
        return this.state;
    }
}
exports.default = DeltaTimer;
