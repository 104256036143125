"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTimerHandler = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const consts_1 = require("@utils/consts");
const timerHandlerStore = (0, toolkit_1.createSlice)({
    name: "timer-handler",
    initialState: {
        value: (localStorage.getItem(consts_1.LocalStorage.TIMER_HANDLER) || "NATIVE"),
    },
    reducers: {
        setTimerHandler: (state, action) => {
            state.value = action.payload;
            localStorage.setItem(consts_1.LocalStorage.TIMER_HANDLER, action.payload);
        },
    },
});
exports.setTimerHandler = timerHandlerStore.actions.setTimerHandler;
exports.default = timerHandlerStore.reducer;
