"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gestures = void 0;
const Gestures = {
    TOUCH: "TOUCH",
    LEFT: "LEFT",
    RIGHT: "RIGHT",
    UP: "UP",
    DOWN: "DOWN",
};
exports.Gestures = Gestures;
