"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatisticsModalUtils = void 0;
const scramble_1 = require("@utils/controllers/scramble");
const helpers_1 = require("@utils/helpers");
class StatisticsModalUtils {
    static getBody(solves) {
        if (!solves)
            return;
        return solves.map((solve, index) => ({
            cells: StatisticsModalUtils.getRow(solve, index),
        }));
    }
    static getRow(solve, index) {
        return new Array({
            value: index + 1,
            centered: true,
        }, {
            value: helpers_1.Formatter.formatTime(solve.getTime),
        }, {
            value: solve.getScramble,
            wrap: true,
            onClick: () => scramble_1.ScrambleController.copyScramble(solve.getScramble),
            tabIndex: 0,
        }, {
            value: helpers_1.Formatter.formatDate(solve.getTimestamp),
        });
    }
}
exports.StatisticsModalUtils = StatisticsModalUtils;
