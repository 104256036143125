"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSidebarList = exports.setPersonalBestsOrder = exports.setPersonalBestsType = exports.setPersonalBestsList = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const consts_1 = require("@utils/consts");
const getInitialBestsList = () => {
    const items = localStorage.getItem(consts_1.LocalStorage.PERSONAL_BESTS_LIST);
    if (items)
        return JSON.parse(items);
    return [...consts_1.Consts.DEFAULT_PERSONAL_BESTS_LIST];
};
const getInitialSidebarList = () => {
    const items = localStorage.getItem(consts_1.LocalStorage.SIDEBAR_LIST);
    if (items)
        return JSON.parse(items);
    return [...consts_1.Consts.DEFAULT_SIDEBAR_LIST];
};
const statisticsStore = (0, toolkit_1.createSlice)({
    name: "statistics",
    initialState: {
        value: {
            sidebarList: getInitialSidebarList(),
            personalBestsList: getInitialBestsList(),
            personalBestsType: localStorage.getItem(consts_1.LocalStorage.PERSONAL_BESTS_TYPE) || consts_1.StatisticType.AVERAGE,
            personalBestsOrder: localStorage.getItem(consts_1.LocalStorage.PERSONAL_BESTS_ORDER) || consts_1.StatisticOrder.BEST,
        },
    },
    reducers: {
        setPersonalBestsList: (state, action) => {
            state.value.personalBestsList = action.payload;
            localStorage.setItem(consts_1.LocalStorage.PERSONAL_BESTS_LIST, JSON.stringify(action.payload));
        },
        setPersonalBestsType: (state, action) => {
            state.value.personalBestsType = action.payload;
            localStorage.setItem(consts_1.LocalStorage.PERSONAL_BESTS_TYPE, action.payload);
        },
        setPersonalBestsOrder: (state, action) => {
            state.value.personalBestsOrder = action.payload;
            localStorage.setItem(consts_1.LocalStorage.PERSONAL_BESTS_ORDER, action.payload);
        },
        setSidebarList: (state, action) => {
            state.value.sidebarList = action.payload;
            localStorage.setItem(consts_1.LocalStorage.SIDEBAR_LIST, JSON.stringify(action.payload));
        },
    },
});
_a = statisticsStore.actions, exports.setPersonalBestsList = _a.setPersonalBestsList, exports.setPersonalBestsType = _a.setPersonalBestsType, exports.setPersonalBestsOrder = _a.setPersonalBestsOrder, exports.setSidebarList = _a.setSidebarList;
exports.default = statisticsStore.reducer;
