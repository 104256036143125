"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCurrent = exports.setSessions = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const consts_1 = require("@utils/consts");
const sessionStore = (0, toolkit_1.createSlice)({
    name: "sessions",
    initialState: {
        value: {
            sessions: [],
            current: undefined,
        },
    },
    reducers: {
        setSessions: (state, action) => {
            state.value.sessions = action.payload;
        },
        setCurrent: (state, action) => {
            state.value.current = action.payload;
            if (action.payload)
                localStorage.setItem(consts_1.LocalStorage.CURRENT_SESSION, action.payload.getIndex.toString());
        },
    },
});
_a = sessionStore.actions, exports.setSessions = _a.setSessions, exports.setCurrent = _a.setCurrent;
exports.default = sessionStore.reducer;
