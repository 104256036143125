"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatabaseController = void 0;
const consts_1 = require("@utils/consts");
class DatabaseController {
    name;
    version;
    ready;
    db;
    static instance;
    constructor(name, version = 4) {
        this.name = name;
        this.version = version;
        this.ready = false;
    }
    initialize() {
        return new Promise((resolve, reject) => {
            if (this.ready)
                return reject("IndexedDB cannot be initialized more than once.");
            const request = window.indexedDB.open(this.name, this.version);
            request.onupgradeneeded = () => {
                this.db = request.result;
                this.manageTables();
            };
            request.onsuccess = () => {
                this.db = request.result;
                if (!this.ready) {
                    this.ready = true;
                    resolve(null);
                }
                reject();
            };
            request.onerror = error => {
                reject(error);
            };
        });
    }
    get(table) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new Promise((resolve, reject) => {
            if (!this.db)
                return reject("The database is not ready");
            const transaction = this.db.transaction(table, "readonly");
            const objectStore = transaction.objectStore(table);
            const request = objectStore.getAll();
            request.onsuccess = () => {
                const result = request.result;
                resolve(result);
            };
            request.onerror = error => reject(error);
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    put(table, value, index) {
        return new Promise((resolve, reject) => {
            if (!this.db)
                return reject("The database is not ready");
            const transaction = this.db.transaction(table, "readwrite");
            const objectStore = transaction.objectStore(table);
            const request = objectStore.put(value, index);
            request.onsuccess = () => resolve(null);
            request.onerror = error => reject(error);
        });
    }
    delete(table, index) {
        return new Promise((resolve, reject) => {
            if (!this.db)
                return reject("The database is not ready");
            const transaction = this.db.transaction(table, "readwrite");
            const objectStore = transaction.objectStore(table);
            const request = objectStore.delete(index);
            request.onsuccess = () => resolve(null);
            request.onerror = error => reject(error);
        });
    }
    manageTables() {
        if (this.ready || !this.db)
            return;
        for (const objectStore of this.db.objectStoreNames) {
            if (consts_1.DatabaseTables[objectStore])
                continue;
            this.db.deleteObjectStore(objectStore);
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [_, value] of Object.entries(consts_1.DatabaseTables)) {
            this.createTable(value);
        }
    }
    createTable(name) {
        if (!this.db || this.db.objectStoreNames.contains(name))
            return;
        this.db.createObjectStore(name);
    }
    deleteTable(name) {
        return new Promise((resolve, reject) => {
            if (!this.db)
                return reject("The database is not ready");
            const transaction = this.db.transaction(name, "readwrite");
            const objectStore = transaction.objectStore(name);
            const request = objectStore.clear();
            request.onsuccess = () => resolve(null);
            request.onerror = error => reject(error);
        });
    }
    closeConnection() {
        if (!this.db)
            return console.error("The database has not yet been initialized.");
        this.ready = false;
        this.db.close();
        this.db = undefined;
    }
    deleteDatabase() {
        window.indexedDB.deleteDatabase(this.name);
        this.db = undefined;
        this.ready = false;
    }
    get isReady() {
        return this.ready && !!this.db;
    }
    static getInstance(name, version) {
        if (!DatabaseController.instance)
            DatabaseController.instance = new DatabaseController(name, version);
        return DatabaseController.instance;
    }
}
exports.DatabaseController = DatabaseController;
