"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkewbColorScheme = exports.PyraminxColorScheme = exports.MegaminxColorScheme = exports.CubeColorScheme = void 0;
const CubeColorScheme = {
    U: { value: "var(--top)", stroke: "var(--black)" },
    R: { value: "var(--right)", stroke: "var(--black)" },
    F: { value: "var(--front)", stroke: "var(--black)" },
    D: { value: "var(--down)", stroke: "var(--black)" },
    L: { value: "var(--left)", stroke: "var(--black)" },
    B: { value: "var(--back)", stroke: "var(--black)" },
};
exports.CubeColorScheme = CubeColorScheme;
const MegaminxColorScheme = {
    U: { value: "var(--top)", stroke: "var(--black)" },
    R: { value: "var(--right)", stroke: "var(--black)" },
    F: { value: "var(--front)", stroke: "var(--black)" },
    dr: { value: "var(--minx-down-right)", stroke: "var(--black)" },
    dl: { value: "var(--minx-down-left)", stroke: "var(--black)" },
    L: { value: "var(--minx-left)", stroke: "var(--black)" },
    d: { value: "var(--minx-down)", stroke: "var(--black)" },
    br: { value: "var(--minx-back-right)", stroke: "var(--black)" },
    BR: { value: "var(--back)", stroke: "var(--black)" },
    BL: { value: "var(--down)", stroke: "var(--black)" },
    bl: { value: "var(--left)", stroke: "var(--black)" },
    b: { value: "var(--minx-back)", stroke: "var(--black)" },
};
exports.MegaminxColorScheme = MegaminxColorScheme;
const PyraminxColorScheme = {
    top: { value: "var(--right)", stroke: "var(--black)" },
    right: { value: "var(--back)", stroke: "var(--black)" },
    left: { value: "var(--front)", stroke: "var(--black)" },
    back: { value: "var(--down)", stroke: "var(--black)" },
};
exports.PyraminxColorScheme = PyraminxColorScheme;
const SkewbColorScheme = {
    top: { value: "var(--top)", stroke: "var(--black)" },
    right: { value: "var(--right)", stroke: "var(--black)" },
    front: { value: "var(--front)", stroke: "var(--black)" },
    bottom: { value: "var(--down)", stroke: "var(--black)" },
    left: { value: "var(--left)", stroke: "var(--black)" },
    back: { value: "var(--back)", stroke: "var(--black)" },
};
exports.SkewbColorScheme = SkewbColorScheme;
