"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const consts_1 = require("@utils/consts");
class Helpers {
    static isMobile() {
        return window.innerWidth <= consts_1.Consts.MOBILE_THRESHOLD;
    }
    static clamp(min, value, max) {
        return Math.min(Math.max(value, min), max);
    }
}
exports.default = Helpers;
