"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardHotkeyController = void 0;
const consts_1 = require("@utils/consts");
const controllers_1 = require("@utils/controllers");
const defaults_1 = require("./defaults");
const util_1 = require("../../util");
class KeyboardHotkeyController {
    databaseController;
    static instance;
    hotkeys;
    constructor() {
        this.databaseController = controllers_1.DatabaseController.getInstance("Timer");
        this.hotkeys = defaults_1.DefaultHotkeys.map(key => (0, util_1.copyKey)(key));
    }
    handleKeyDown = (event) => {
        const hotkey = this.hotkeys.find(hotkey => hotkey.keys.includes(event.code));
        if (!hotkey || !hotkey.isAllowed())
            return false;
        event.preventDefault();
        hotkey.action();
        return true;
    };
    setKey(name, index, value) {
        if (!this.hotkeys)
            return;
        const hotkeyIndex = this.hotkeys.findIndex(hotkey => hotkey.name === name);
        if (hotkeyIndex < 0)
            return;
        this.hotkeys[hotkeyIndex].keys[index] = value;
        const data = {
            name: this.hotkeys[hotkeyIndex].name,
            keys: this.hotkeys[hotkeyIndex].keys,
            active: this.hotkeys[hotkeyIndex].active,
        };
        this.databaseController
            .put(consts_1.DatabaseTables.KEYBINDINGS, data, this.hotkeys[index].name)
            .catch(error => console.error(error));
    }
    setActive(name, value) {
        if (!this.hotkeys)
            return;
        const index = this.hotkeys.findIndex(hotkey => hotkey.name === name);
        if (index < 0)
            return;
        this.hotkeys[index].active = value;
        const data = {
            name: this.hotkeys[index].name,
            keys: this.hotkeys[index].keys,
            active: this.hotkeys[index].active,
        };
        this.databaseController
            .put(consts_1.DatabaseTables.KEYBINDINGS, data, this.hotkeys[index].name)
            .catch(error => console.error(error));
    }
    setBeingModified(name, value) {
        setTimeout(() => {
            if (!this.hotkeys)
                return;
            const index = this.hotkeys.findIndex(hotkey => hotkey.name === name);
            if (index < 0)
                return;
            this.hotkeys[index].isBeingModified = value;
        }, 0);
    }
    resetHotkey(name) {
        if (!this.hotkeys)
            return;
        const index = this.hotkeys.findIndex(hotkey => hotkey.name === name);
        if (index < 0)
            return;
        this.hotkeys[index] = (0, util_1.copyKey)(defaults_1.DefaultHotkeys[index]);
        this.databaseController
            .delete(consts_1.DatabaseTables.KEYBINDINGS, this.hotkeys[index].name)
            .catch(error => console.error(error));
    }
    resetToDefaults() {
        this.hotkeys = defaults_1.DefaultHotkeys.map(key => (0, util_1.copyKey)(key));
    }
    get getHotkeys() {
        return this.hotkeys;
    }
    async loadHotkeys() {
        const hotkeys = defaults_1.DefaultHotkeys.map(key => (0, util_1.copyKey)(key));
        await this.databaseController
            .get(consts_1.DatabaseTables.KEYBINDINGS)
            .then((res) => {
            res.forEach(storedHotkey => {
                const index = hotkeys.findIndex(hotkey => hotkey.name === storedHotkey.name);
                if (index < 0)
                    return;
                hotkeys[index].name = storedHotkey.name;
                hotkeys[index].keys = storedHotkey.keys;
                hotkeys[index].active = storedHotkey.active;
            });
        })
            .catch(error => console.error(error));
        this.hotkeys = hotkeys;
    }
    static getInstance() {
        if (!KeyboardHotkeyController.instance)
            KeyboardHotkeyController.instance = new KeyboardHotkeyController();
        return KeyboardHotkeyController.instance;
    }
}
exports.KeyboardHotkeyController = KeyboardHotkeyController;
