"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTheme = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const handlers_1 = require("@utils/handlers");
const themeStore = (0, toolkit_1.createSlice)({
    name: "theme",
    initialState: {
        value: handlers_1.ThemeHandler.getCurrentTheme(),
    },
    reducers: {
        setTheme: (state, action) => {
            state.value = action.payload;
        },
    },
});
exports.setTheme = themeStore.actions.setTheme;
exports.default = themeStore.reducer;
