"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const HotkeyHandler_1 = require("./hotkeys/HotkeyHandler");
class InputHandler {
    hotkeyHandler;
    timerHandler;
    static instance;
    constructor() {
        this.hotkeyHandler = HotkeyHandler_1.HotkeyHandler.getInstance();
    }
    registerHotkeys(ref) {
        this.hotkeyHandler.registerHotkeys(ref);
    }
    unregisterHotkeys(ref) {
        this.hotkeyHandler.unregisterHotkeys(ref);
    }
    registerTimer(handler, ref) {
        this.timerHandler = handler;
        this.timerHandler.registerTimer(ref);
    }
    unregisterTimer(ref) {
        if (!this.timerHandler)
            return;
        this.timerHandler.unregisterTimer(ref);
        this.timerHandler = undefined;
    }
    static getInstance() {
        if (!InputHandler.instance)
            InputHandler.instance = new InputHandler();
        return InputHandler.instance;
    }
}
exports.default = InputHandler;
