"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setScramble = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const scrambleStore = (0, toolkit_1.createSlice)({
    name: "scramble",
    initialState: {
        value: "",
    },
    reducers: {
        setScramble: (state, action) => {
            state.value = action.payload;
        },
    },
});
exports.setScramble = scrambleStore.actions.setScramble;
exports.default = scrambleStore.reducer;
