"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TouchThresholds = exports.StatisticOrder = exports.StatisticType = exports.StatisticThreshold = exports.LocalStorage = exports.DatabaseTables = exports.Consts = void 0;
const StatisticType = {
    SINGLE: "SINGLE",
    MEAN: "MEAN",
    AVERAGE: "AVERAGE",
};
exports.StatisticType = StatisticType;
const StatisticOrder = {
    CURRENT: "CURRENT",
    BEST: "BEST",
};
exports.StatisticOrder = StatisticOrder;
const Consts = {
    MOBILE_THRESHOLD: 768,
    MAX_PENALTIES: 8,
    MAX_MODAL_AMOUNT: 2,
    TIMER_STATE_READY_AT: 800,
    TIMER_UPDATE_FREQUENCY: 10,
    DIAGRAM_WIDTH: 300,
    DIAGRAM_HEIGHT: 300,
    DIAGRAM_STROKE_WIDTH: 0.005,
    DEFAULT_PERSONAL_BESTS_LIST: ["1", "5", "12", "50", "100", "250"],
    DEFAULT_SIDEBAR_LIST: [
        { type: StatisticType.SINGLE, order: StatisticOrder.CURRENT, amount: "1" },
        { type: StatisticType.SINGLE, order: StatisticOrder.BEST, amount: "1" },
        { type: StatisticType.AVERAGE, order: StatisticOrder.CURRENT, amount: "5" },
        { type: StatisticType.AVERAGE, order: StatisticOrder.BEST, amount: "5" },
    ],
};
exports.Consts = Consts;
const DatabaseTables = {
    SESSIONS: "SESSIONS",
    KEYBINDINGS: "KEYBINDINGS",
    COLOR_SCHEMES: "COLOR_SCHEMES",
};
exports.DatabaseTables = DatabaseTables;
const LocalStorage = {
    CURRENT_SESSION: "currentSession",
    DIAGRAM: "diagram",
    SIDEBAR: "sidebar",
    THEME: "theme",
    PERSONAL_BESTS_LIST: "personalBestsList",
    PERSONAL_BESTS_TYPE: "personalBestsType",
    PERSONAL_BESTS_ORDER: "personalBestsOrder",
    SIDEBAR_LIST: "sidebarList",
    COLOR_PROFILE: "colorProfile",
    TIMER_HANDLER: "timerHandler",
};
exports.LocalStorage = LocalStorage;
const StatisticThreshold = {
    SINGLE: 1,
    MEAN: 3,
    AVERAGE: 5,
};
exports.StatisticThreshold = StatisticThreshold;
const TouchThresholds = {
    HORIZONTAL: 3,
    VERTICAL: 10,
};
exports.TouchThresholds = TouchThresholds;
