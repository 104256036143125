"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SCRAMBLE_TYPES = void 0;
const SCRAMBLE_TYPES = [
    { value: "222", label: "2 x 2" },
    { value: "333", label: "3 x 3" },
    { value: "444", label: "4 x 4" },
    { value: "555", label: "5 x 5" },
    { value: "666", label: "6 x 6" },
    { value: "777", label: "7 x 7" },
    { value: "minx", label: "Megaminx" },
    { value: "pyram", label: "Pyraminx" },
    { value: "skewb", label: "Skewb" },
    { value: "sq1", label: "Square-1" },
    { value: "clock", label: "Clock" },
];
exports.SCRAMBLE_TYPES = SCRAMBLE_TYPES;
