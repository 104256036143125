"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatisticType = exports.StatisticsController = void 0;
const consts_1 = require("@utils/consts");
Object.defineProperty(exports, "StatisticType", { enumerable: true, get: function () { return consts_1.StatisticType; } });
class StatisticsController {
    static getCurrent(session, amount, upToDate) {
        let solves = [...session.getSolves];
        if (upToDate && upToDate !== "") {
            const threshold = new Date(upToDate).getTime();
            solves = solves.filter(solve => new Date(solve.getTimestamp).getTime() <= threshold);
        }
        return solves.slice(solves.length - amount, solves.length);
    }
    static getBest(session, amount, type, upToDate) {
        let solves = [...session.getSolves];
        if (upToDate && upToDate !== "") {
            const threshold = new Date(upToDate).getTime();
            solves = solves.filter(solve => new Date(solve.getTimestamp).getTime() <= threshold);
        }
        if (solves.length < amount || amount < consts_1.StatisticThreshold[type])
            return [];
        if (type === consts_1.StatisticType.SINGLE) {
            solves.sort((a, b) => a.getTime - b.getTime);
            return solves.slice(0, 1);
        }
        return StatisticsController.getBestStatisticSolves(solves, amount, type);
    }
    static calculateMean(solves) {
        const mean = [...solves].map(solve => solve.getTime);
        return mean.reduce((a, b) => a + b) / mean.length;
    }
    static calculateAverage(solves) {
        const subtractValue = Math.ceil(Math.ceil(solves.length / 20) / 2);
        const times = [...solves].map(solve => solve.getTime);
        const average = times.sort((a, b) => a - b).slice(subtractValue, -subtractValue);
        return average.reduce((a, b) => a + b) / average.length;
    }
    static getBestStatisticSolves(solves, amount, type) {
        const calculate = type === consts_1.StatisticType.MEAN
            ? StatisticsController.calculateMean
            : StatisticsController.calculateAverage;
        const movingAverage = new Array();
        for (let i = 0; i < solves.length + 1 - amount; i++) {
            movingAverage.push(new Array(amount).fill(null).map((_, index) => solves[i + index]));
        }
        movingAverage.sort((a, b) => calculate(a) - calculate(b));
        return movingAverage[0];
    }
}
exports.StatisticsController = StatisticsController;
